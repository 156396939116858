// src/MyComponent.jsx
import React, { useState } from 'react';
import './style.css';
import { ethers } from 'ethers';
import { Contract } from '@ethersproject/contracts';
import ethereumIcon from './image/ethereum.png';
import LoadingIndicator from './LoadingIndicator';


// 合约地址
const contractAddress = '0x61728Bcb0caA8eF4881e239504d2C27149A0F0ca';
// 合约ABI
const contractABI = [
  {
    "constant": false,
    "inputs": [],
    "name": "withdraw",
    "outputs": [],
    "type": "function"
  },
];


function MyComponent() {
  const [connectedAccount, setConnectedAccount] = useState("");
  const [loading, setLoading] = useState(false);

  const connectToBlockchain = async () => {
    try {
      if (typeof window.ethereum !== "undefined") {

        await window.ethereum.request({ method: "eth_requestAccounts" });

        // 切换链
        const chainId = 1; // 目标链的ID，例如1表示以太坊主网
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${chainId.toString(16)}` }],
          });
        } catch (error) {
          if (error.code === 4902) {
            // 用户拒绝了切换链的请求
            console.log("User rejected chain switch");
            alert("User rejected chain switch");
          } else {
            console.log("网络错误");
            alert("网络错误");
            // 其他错误
            console.error(error);
          }
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const [address] = await Promise.all([signer.getAddress()]);
        const shortenedAddress = address.substring(0, 8) + "..." + address.substring(address.length - 4);
        setConnectedAccount(shortenedAddress);
      } else {
        alert("小狐狸未安装")
        // 处理用户拒绝连接的情况
        console.log("小狐狸未安装");
      }
    } catch (error) {
      alert("连接失败")
      console.error("连接失败:", error);
    }
  };

  const handleConnectWallet = () => {
    connectToBlockchain();
  };

  const handleWithdraw = async () => {
    if (typeof window.ethereum != "undefined") {
      setLoading(true); // 设置加载状态为true
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new Contract(contractAddress, contractABI, signer);

      try {
        const tx = await contract.withdraw();
        console.log("Transaction hash:", tx.hash);

        // 等待交易被确认
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        let receipt = null;

        // 持续查询交易状态，直到交易被确认
        while (!receipt) {
          receipt = await provider.getTransactionReceipt(tx.hash);
          await new Promise(resolve => setTimeout(resolve, 3000)); // 每隔3秒查询一次
        }

        console.log("Transaction receipt:", receipt);
      } catch (error) {
        console.error("提现失败", error);
        alert("Casting failure, insufficient balance, please recharge")
      } finally {
        setLoading(false); // 设置加载状态为false
      }
    }
  };

  return (
    <div className="box">
      {loading && <LoadingIndicator />} {/* 显示全局加载指示器 */}

      <div className="header"></div>
      <div className="text1" onClick={handleConnectWallet}>
        {connectedAccount ? <><img src={ethereumIcon} alt="Ethereum Icon" className="ethereumIcon" /> {connectedAccount}</> : "CONNECT WALLET"}
      </div>
      <div>  
        <div className="withdraw-button" onClick={handleWithdraw}>
         Withdraw
      </div></div>
    </div>
  );
}

export default MyComponent;

